/**
 * Constants file that provides info for upcoming seminar page
 */
export default {
    flyerLocation: "https://assets.almaghrib.org/img/common/courses/24-7/poster/sm.jpg",
    promoVideoUrl: "https://www.youtube.com/embed/3yeOrP165q8",
    seminarName: "24/7: Fiqh of Du'a & Dhikr",
    seminarInstructor: "Shaikh Waleed Basyouni",
    seminarDescription: "It’s the most difficult relationship for many to maintain - the relationship with Allah. After many ups and downs, learn how to transform it once and for all. This class enters the world of Dua and Dhikr. You will learn how to keep your tongue moist with Dhikr, resuscitate Dua from robotic to passionate, and seamlessly remember Allah throughout your day. Learn the etiquette of Dua and its fiqh, and what to do when all hope is lost.",
    seminarDate: "02/07/20",
    // Has to be in 24-hour time
    seminarTime: "19:30",
    aboutInstructor: "Vice-President of AlMaghrib Institute. Senior Scholar. Head of Islamic Theology and Ethics Department. Master’s in Islamic Theology, World Religions and Modern Religious Sects. PhD in Theology. Imam of Clear Lake Islamic Center. Director of Texas Da‘wah Convention. Gives fatwah as member of American Muslim Jurists Association (AMJA). Answers fiqh questions on Twitter.",
    registrationLink: "https://www.almaghrib.org/newjersey",
    moreAboutInstructorLink: "https://www.almaghrib.org/instructors/waleed-basyouni",
    instructorImageLocation: "https://assets.almaghrib.org/img/common/instructors/waleed-basyouni/head.jpg",
}