import React, {Component} from 'react';


export default class Donate extends Component {
    render() {
        return(
            <div>
                {"Donate"}
            </div>
        )
    }
}