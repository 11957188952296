import React, {Component} from 'react';


export default class ExamRes extends Component {
    render() {
        return(
            <div>
                {"Exam Resources"}
            </div>
        )
    }
}